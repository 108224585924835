
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import background from '@public/images/bg.png';

export default function FourOhFour() {
  const { t } = useTranslation('home');
  return (
    <div className='fourohfour'>
      <div className='fourohfour__background'>
        <Image
          src={background}
          alt='background'
          layout='fill'
          quality={100}
          priority={true}
          objectFit='cover'
        />
      </div>

      <div className='fourohfour__content'>
        <div className='fourohfour__content--coming-soon'>
          {t('comingSoon')}
        </div>
      </div>
    </div>
  );
}


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  